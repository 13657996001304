<ng-container *transloco="let t; read: 'card-item'">
  <div class="card-item-container card {{selected ? 'selected-highlight' : ''}}">
    <div class="overlay" (click)="handleClick($event)">
      @if (total > 0 || suppressArchiveWarning) {
        <app-image height="230px" width="158px" [styles]="{'border-radius': '.25rem .25rem 0 0'}" [imageUrl]="imageUrl"></app-image>
      } @else if (total === 0 && !suppressArchiveWarning) {
        <app-image height="230px" width="158px" [styles]="{'border-radius': '.25rem .25rem 0 0'}" [imageUrl]="imageService.errorImage"></app-image>
      }

      <div class="progress-banner">
        @if (read > 0 && read < total && total > 0 && read !== total) {
          <p ngbTooltip="{{((read / total) * 100) | number:'1.0-1'}}% Read">
            <ngb-progressbar type="primary" height="5px" [value]="read" [max]="total"></ngb-progressbar>
          </p>
        }

        <span class="download">
          <app-download-indicator [download$]="download$"></app-download-indicator>
        </span>
      </div>

      @if(total === 0 && !suppressArchiveWarning) {
        <div class="error-banner">
          {{t('cannot-read')}}
        </div>
      }

      @if (read === 0 && total > 0) {
        <div class="badge-container">
          <div class="not-read-badge"></div>
        </div>
      }

      @if (allowSelection) {
        <div class="bulk-mode {{bulkSelectionService.hasSelections() ? 'always-show' : ''}}" (click)="handleSelection($event)">
          <input type="checkbox" class="form-check-input" attr.aria-labelledby="{{title}}_{{entity.id}}" [ngModel]="selected" [ngModelOptions]="{standalone: true}">
        </div>
      }

      @if (count > 1) {
        <div class="count">
          <span class="badge bg-primary">{{count}}</span>
        </div>
      }

      <div class="card-overlay"></div>
      @if (overlayInformation | safeHtml; as info) {
        @if (info !== '' ||  info !== null) {
          <div class="overlay-information {{centerOverlay ? 'overlay-information--centered' : ''}}">
            <div class="position-relative">
              <span class="card-title library mx-auto" style="width: auto;" [ngbTooltip]="info" placement="top" [innerHTML]="info"></span>
            </div>
          </div>
        }
      }
    </div>
    @if (title.length > 0 || actions.length > 0) {
      <div class="card-body">
        <div>
          <span class="card-title" placement="top" id="{{title}}_{{entity.id}}" [ngbTooltip]="tooltipTitle" (click)="handleClick($event)" tabindex="0">
            <app-promoted-icon [promoted]="isPromoted()"></app-promoted-icon>
            <app-series-format [format]="format"></app-series-format>
            {{title}}
          </span>
          @if (actions && actions.length > 0) {
            <span class="card-actions float-end">
              <app-card-actionables (actionHandler)="performAction($event)" [actions]="actions" [labelBy]="title"></app-card-actionables>
            </span>
          }
        </div>

        @if (subtitleTemplate) {
          <div style="text-align: center">
            <ng-container [ngTemplateOutlet]="subtitleTemplate" [ngTemplateOutletContext]="{ $implicit: entity }"></ng-container>
          </div>
        }
        @if (!suppressLibraryLink && libraryName) {
          <a class="card-title library" [routerLink]="['/library', libraryId]" routerLinkActive="router-link-active">
            {{libraryName | sentenceCase}}
          </a>
        }
      </div>
    }
  </div>

</ng-container>
